.black-banner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background: #000;
  width: 100%;
  padding: 50px 0;

  h1 {
    color: var(--Black-0, #FFF);

    /* h2 */
    font-family: "Open Sans";
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 50.4px; /* 120% */
    @media (max-width: 1200px) {
      color: var(--Black-0, #FFF);
      font-family: "Open Sans";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 50.4px; /* 180% */
      text-align: center;
    }
  }

  p {
    color: var(--Black-0, #FFF);

    /* copy h2 */
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    @media (max-width: 1200px) {
      color: var(--Black-0, #FFF);
      text-align: center;
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
}