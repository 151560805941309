.container {
  width: 100%;
  height: 32px;
  background: #050505;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1512px !important;

  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
}

.imgBox {
  img {
    object-fit: contain;
    width: 38.823px;
    height: 25.882px;
    transition: 200ms;
    &:hover {
      scale: 1.1;
    }
  }
}

.text,
a {
  color: var(--Black-0, #fff);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  transition: 200ms;
}
a {
  &:hover {
    color: #d1d1d1;
  }
}

.line {
  height: 19px;
  width: 1px;
  background: #6d6d6d;
  margin-right: 22px;
  margin-left: 22px;
}
