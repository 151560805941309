.container {
}

.MuiDrawer-paper {
  width: 100%;
}

.insideDrawer {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 100px;

  .insideBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    h3 {
      color: #525252;
      text-align: center;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      margin: 0;
    }

    p {
      color: var(--Black-0, #fff);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }

    button {
      display: flex;
      padding: 5px 16px;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      background: transparent;
      border: none;
    }
  }
}

.box1 {
  padding: 15px;
  z-index: 2;

  @media only screen and (max-width: 1200px) {
    clip-path: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    width: 100%;
  }
}

.logo {
  height: 100%;
  cursor: pointer;
}

.menu {
  display: none;
  @media only screen and (max-width: 1200px) {
    display: block;
  }
}

.box2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin-left: 20%;

  a {
    padding: 10px;
  }

  @media (max-width: 1200px) {
    gap: 25px;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.box3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  background: #ffffff;
  clip-path: polygon(12% 0, 100% 0, 100% 100%, 8% 101%);
  @media only screen and (max-width: 1200px) {
    display: none;
  }

  .red-button {
  }
}

.insidePopper {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--Black-0, #fff);
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.1);

  button {
    border-radius: 4px;
    width: 100%;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 15px;
    gap: 8px;
    flex-direction: row;
    align-self: stretch;
    background: #fff;
    cursor: pointer;

    p {
      color: var(--Black-950, #050505);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.text1 {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: #6d6d6d;
  font-family: Inter;
  font-size: 16px;
  transition: 200ms;
  @media only screen and (max-width: 1330px) {
    font-size: 12px;
  }
}

.text1:hover {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ec1c23;
  font-family: Inter;
  font-size: 16px;
  // font-weight: 600;
  @media only screen and (max-width: 1330px) {
    font-size: 12px;
  }
}

a {
  svg {
    stroke: #6d6d6d;
  }

  &.selected-svg {
    stroke: #ec1c23;
  }
}

a:hover {
  svg {
    stroke: #ec1c23;
  }
}

.text2 {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: #ec1c23;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  @media only screen and (max-width: 1330px) {
    font-size: 12px;
  }
}
