.excellent-service-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 61px;
  align-self: stretch;
  padding: 80px 5%;
  width: calc(100% - 10%);
  @media (max-width: 1200px) {
    gap: 40px;
    width: 100%;
  }
  @media (min-width: 1800px) {
    max-width: 1512px;
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (max-width: 1200px) {
    padding: 80px 0%;
  }

  .top-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    position: relative;
    padding-left: 22%;
    padding-right: 22%;
    @media (max-width: 1200px) {
      padding-left: 5%;
      padding-right: 5%;
    }

    .red-alert {
      display: flex;
      padding: 8px 10px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      background: var(--Primary-600, #ec1c23);
      position: absolute;
      top: 0;
      left: 0;
      @media (max-width: 1200px) {
        top: -40px;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      p {
        color: var(--Black-0, #fff);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    p {
      color: #727272;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 162.5% */
      text-align: center;
      @media (max-width: 1200px) {
        font-size: 14px;
      }
    }

    h2 {
      color: #16171a;
      text-align: center;
      font-family: "Open Sans";
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 50.4px; /* 120% */
      @media (max-width: 1200px) {
        font-size: 28px;
        line-height: unset;
      }
    }

    .top-part-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
  }

  .cart-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    @media (max-width: 1200px) {
      flex-direction: column;
      padding: 0 5%;
    }
  }

  .portfolio-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    @media (max-width: 1200px) {
      padding-left: 5%;
    }

    .swiper-slide {
      background: transparent;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      justify-content: flex-start;

      p {
        color: #16171a;
        text-align: center;
        font-family: "DM Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        @media (max-width: 1200px) {
          font-size: 16px;
        }
      }
    }

    h2 {
      color: #16171a;
      text-align: center;
      font-family: "Open Sans";
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 50.4px; /* 120% */
      @media (max-width: 1200px) {
        font-size: 28px;
      }
    }

    .swiper,
    .swiper-wrapper {
      height: auto;
    }
  }
}
