.container {
  width: 100%;
  background: #16171a;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 1800px) {
    width: calc(100% - 5%);
    padding-left: 5%;
    max-width: 1512px;
  }

  .top {
    padding: 80px 0 32px 0;
    width: 100%;
    @media screen and (max-width: 900px) {
      padding: 80px 16px 32px;
    }

    .head {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 900px) {
        display: block;
      }

      .hed_left {
        width: 401px;

        @media screen and (max-width: 900px) {
          width: 100%;
          text-align: center;
        }

        .logo {
          img {
            @media screen and (max-width: 900px) {
              width: 95px;
              height: 48px;
            }
          }
        }

        .text {
          @media screen and (max-width: 900px) {
            margin-bottom: 50px;
            width: 80vw;
            font-size: 12px;
          }
          p {
            margin: 0;
            padding: 8px 0;
            color: #bbbbbb;
          }
        }
      }

      .hed_right {
        display: flex;
        width: 871px;

        @media only screen and (max-width: 900px) {
          width: 100%;
          font-size: 11px;
          flex-wrap: wrap;
          gap: 22px;
        }

        .list {
          width: calc(33% - 32px);
          color: #6d6d6d;
          @media only screen and (max-width: 786px) {
            font-size: 11px;
            width: 40vw;
          }

          ul {
            margin: 0;
            list-style-type: none;
            font-size: 16px;
            padding: 0 24px;
            @media only screen and (max-width: 786px) {
              font-size: 11px;
              padding: 0 10px;
              margin: 0;
            }

            li {
              padding: 8px 0;
              a {
                color: #6d6d6d;
                &:hover {
                  color: #d1d1d1;
                  p {
                    color: #d1d1d1;
                  }
                }
              }
              p {
                margin: 0;
                display: flex;
                align-items: center;
                color: #d1d1d1;
                transition: 200ms;
                img {
                  padding-right: 8px;
                  @media only screen and (max-width: 786px) {
                    //width: 18px;
                    height: 18px;
                    // width: 30%;
                  }
                }

                &:last-child {
                  padding-left: 32px;
                  color: #6d6d6d;
                  @media only screen and (max-width: 786px) {
                    padding-left: 2px;
                  }
                }
              }

              &:first-child {
                color: #ffffff;
                height: 51px;
                padding: 0;
                list-style: none;
              }
            }
          }
        }
      }
    }

    .foot {
      @media screen and (max-width: 900px) {
        width: 90vw;
        display: flex;
        justify-content: center;
        align-self: center;
      }

      img {
        @media screen and (max-width: 900px) {
          width: 135px;
          height: 68px;
          margin-top: 50px;
        }
      }
    }
  }
}

.bottom {
  height: 21px;
  background: #ec1c23;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  width: calc(100%);
  padding: 17px 0;

  @media (min-width: 1800px) {
    padding-left: 5%;
    padding-right: 5%;
    width: calc(100% - 10%);
    max-width: 1512px;
  }
  @media screen and (max-width: 950px) {
    display: block;
    height: auto;
  }
  @media screen and (max-width: 900px) {
    padding: 17px 21px;
    width: calc(100% - 42px);
  }

  .left {
    @media screen and (max-width: 950px) {
      text-align: center;
    }
    @media screen and (max-width: 900px) {
      font-size: 13px;
    }
  }

  .right {
    display: flex;
    gap: 24px;
    @media screen and (max-width: 950px) {
      justify-content: center;
    }
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  a {
    color: #ffffff;
    &:hover {
      color: #d1d1d1;
    }
  }
  p {
    margin: 0;
    color: #ffffff;
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    line-height: 21px;
  }
}
