.cart {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  border-bottom: 1px solid var(--Black-100, #E7E7E7);
  background: var(--Black-0, #FFF);
  max-width: 33%;
  gap: 40px;
  align-self: stretch;
  @media (max-width: 1200px) {
    max-width: 100%;
    min-width: unset;
    gap: 60px;
  }

  .image-part {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: 228px;
      object-fit: cover;
    }

    .red-circle {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--Primary-600, #EC1C23);
      border-radius: 100%;
      border: 10px solid var(--Primary-100, #FFE0E1);
      position: absolute;
    }
  }

  .bottom-part {
    display: flex;
    padding: 0 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    height: 100%;
    justify-content: space-between;

    .top-part {
      display: flex;
      padding-bottom: 20px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      padding-left: unset;
      padding-right: unset;
      @media (max-width: 1200px) {
        gap: 6px;
        padding-bottom: unset;
      }

      h4 {
        color: #16171A;
        text-align: center;
        font-family: "DM Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 50.4px; /* 210% */
        @media (max-width: 1200px) {
          font-size: 18px;
          line-height: unset;
        }

        p {
          color: #727272;
          text-align: center;
          font-family: "DM Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          @media (max-width: 1200px) {
            font-size: 14px;
            line-height: unset;
          }
        }
      }
    }

    .features-boxes {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px;
      align-self: stretch;
      flex-wrap: wrap;

      .box {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        background: var(--Black-50, #F6F6F6);

        p {
          color: #727272;
          text-align: center;
          font-family: "DM Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */
          @media (max-width: 1200px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}