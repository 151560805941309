.privacy-policy-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;

  p {
    color: #000;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }

  .rules-wrapper {
    max-width: 1512px;

    display: flex;
    padding: 80px 0;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    gap: 10%;
    @media (max-width: 1200px) {
      flex-direction: column;
      padding: 40px 0;
      gap: 40px;
    }

    .left-side-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      min-width: 45%;

      .other {
        color: #727272;
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        @media (max-width: 1200px) {
          color: #000;
          font-family: "DM Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */
        }
      }
    }

    .right-side-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      min-width: 45%;

      p {
        color: #727272;
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }
}
