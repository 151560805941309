.insideModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  min-width: 90%;
  min-height: 90%;
  flex-direction: row;
  @media (max-width: 1200px) {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 80vh;
  }

  .leftSide {
    width: 40%;
    position: relative;
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("../assets/construction2.jpg") lightgray 50% / cover repeat-y;
    @media (max-width: 1200px) {
      width: calc(100% - 32px);
      padding: 60px 16px;
    }

    .whiteButton {
      display: flex;
      height: 56px;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      position: absolute;
      left: 40px;
      top: 40px;
      border: 1px solid var(--Black-100, #e7e7e7);
      color: var(--Black-0, #fff);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: transparent;
      cursor: pointer;
      @media (max-width: 1200px) {
        left: 10px;
        top: 10px;
      }
    }

    h1 {
      color: var(--Black-0, #fff);
      font-family: "Open Sans";
      font-size: 64px;
      font-style: italic;
      font-weight: 700;
      line-height: 80.3px; /* 125.469% */
      @media (max-width: 1200px) {
        color: var(--Black-0, #fff);
        font-family: "Open Sans";
        font-size: 36px;
        font-style: italic;
        font-weight: 700;
        line-height: 150%; /* 54px */
      }
    }

    p {
      color: var(--Black-0, #fff);

      /* copy h1 */
      font-family: "DM Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
      margin: 0;
      @media (max-width: 1200px) {
        color: var(--Black-0, #fff);

        /* copy h2 */
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }

  .rightSide {
    display: flex;
    padding: 32px 48px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 90vh;
    @media (max-width: 1200px) {
      padding: 16px 24px;
    }

    .rowInputs {
      display: flex;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      @media (max-width: 1200px) {
        flex-direction: column;
      }

      .input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        @media (max-width: 1200px) {
          width: 100%;
        }

        p {
          color: var(--Black-950, #050505);
          font-family: "DM Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px; /* 166.667% */
          margin: 0;
        }

        input {
          display: flex;
          height: 48px;
          padding: 8px 16px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          background: var(--Black-50, #f6f6f6);
          border: none;
          width: calc(100% - 32px);
        }

        input:focus {
          outline: none;
        }

        input::placeholder {
          color: var(--Black-500, #6d6d6d);
          font-family: "DM Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 187.5% */
        }

        textarea {
          display: flex;
          padding: 16px;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          background: var(--Black-50, #f6f6f6);
          border: none;
        }

        textarea::placeholder {
          color: var(--Black-500, #6d6d6d);
          font-family: "DM Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 187.5% */
        }

        textarea:focus {
          outline: none;
        }

        .selectWrapper {
          display: flex;
          align-self: stretch;
          align-items: center;
          flex: 1 0 0;
          width: 100%;
          gap: 10px;
        }
      }
    }

    .redButton {
      display: flex;
      padding: 24px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      background: var(--Primary-600, #ec1c23);
      color: var(--Black-0, #fff);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
      transition: 300ms;
      cursor: pointer;
      &:hover {
        color: white;
        opacity: 0.9;
      }
      &:disabled {
        background: #bd141a;
        cursor: default;
        &:hover {
          opacity: 1;
        }
      }
      @media (max-width: 1200px) {
        padding: 12px 16px;
      }
    }

    .radioGroup {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
      align-self: stretch;

      p {
        color: var(--Black-950, #050505);
        font-family: "DM Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 166.667% */
        margin: 0;
      }

      .groupWrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
        align-self: stretch;

        span {
          padding: 0;
        }

        .radio2 {
          border: 1px solid var(--Black-100, #e7e7e7);
          background: var(--Black-50, #f6f6f6);
          display: flex;
          padding: 8px 16px;
          align-items: center;
          gap: 16px;
          flex: 1 0 0;
          margin-right: 0;
          margin-left: 0;
          width: 100%;
          height: 48px;
        }

        .radio1 {
          border: 1px solid var(--Primary-300, #ff9ea1);
          background: var(--Primary-50, #fff1f1);
          display: flex;
          padding: 8px 16px;
          align-items: center;
          gap: 16px;
          flex: 1 0 0;
          margin-right: 0;
          margin-left: 0;
          width: 100%;
          height: 48px;

          span {
            color: red;
          }
        }
      }
    }
  }
  .Submitting {
    display: flex;
    width: 50%;
    align-self: center;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 50.4px; /* 120% */
  }
  .thanks {
    display: flex;
    // width: 799px;
    // height: 510px;
    padding: 32px;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
    background: var(--Black-0, #fff);
    align-self: stretch;
    img {
      max-width: 162.5px;
    }
    h3 {
      color: #000;
      text-align: center;
      font-family: "Open Sans";
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 50.4px; /* 120% */
      margin: 0;
    }
    p {
      color: #353535;
      text-align: center;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
}
