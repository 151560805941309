.container {
  height: 616px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-bottom: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f2e9e9;
  align-items: center;
  @media (max-width: 1200px) {
    height: unset;
    // background-size: auto;
    width: calc(100% - 8%);
    padding: 40px 4% 420px;
  }
  @media (max-width: 600px) {
    background-position: left 59% bottom;
    background-size: auto;
  }
}

.box1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 80px 0;
  gap: 32px;
  flex: 1 0 0;
  max-width: 1512px;
  @media (max-width: 1200px) {
    padding: 0;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 500px) {
    margin-bottom: 60px;
  }
}

.boxButton {
  display: flex;
  gap: 16px;
  margin-top: 10px;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    margin-top: 0;
  }

  button {
    @media (max-width: 360px) {
      width: 100%;
    }
  }
}

.textRed {
  color: #ec1c23;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
}

.title {
  color: #16171a;
  font-family: "Open Sans";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;

  @media only screen and (max-width: 786px) {
    font-size: 28px;
  }
}

.text {
  color: #727272;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 383px;
  text-align: left;
  @media (max-width: 1200px) {
    font-size: 14px;
    text-align: center !important;
  }
}

.textBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 64px;

  @media (max-width: 1200px) {
    align-items: center;
    gap: 8px;
  }
}
