.banner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .inside-wrapper {
    display: flex;
    padding-left: 5%;
    align-items: center;
    gap: 19px;
    align-self: stretch;
    background: #fff;
    height: 750px;
    width: 100%;
    @media (max-width: 1200px) {
      flex-direction: column-reverse;
      justify-content: center;
      height: unset;
      padding-left: unset;
    }
    @media (min-width: 1800px) {
      max-width: 1512px;
      padding-right: 0;
      padding-left: 0;
    }

    .left-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      height: 100%;
      @media (max-width: 1200px) {
        padding-right: 5%;
        padding-left: 5%;
        align-items: center;
      }

      .top-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        height: 700px;

        .text-part {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 24px;
          align-self: stretch;
          transition: opacity 0.5s ease;
          width: 100%;
          flex-wrap: wrap;
          max-width: 80%;
          min-height: 450px;
          @media (max-width: 1200px) {
            gap: 8px;
            align-items: center;
            max-width: 100%;
            min-height: auto;
          }

          .red-button {
            margin-bottom: 20px;
          }

          .red {
            color: #ec1c23;
            /* h1 */
            font-family: "Open Sans";
            font-size: 80px;
            font-style: italic;
            font-weight: 700;
            line-height: 80.3px;
            @media (max-width: 1200px) {
              font-size: 45px;
              text-align: center;
            }
          }

          h1 {
            color: #050505;
            /* h1 */
            font-family: "Open Sans";
            font-size: 80px;
            font-style: italic;
            font-weight: 700;
            line-height: 80.3px; /* 100.375% */
            width: 100%;
            @media (max-width: 1200px) {
              font-size: 45px;
              text-align: center;
            }
          }

          h3 {
            color: #16171a;
            /* h2 */
            font-family: "Open Sans";
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 50.4px; /* 120% */
            width: 100%;
            text-wrap: normal;
            @media (max-width: 1200px) {
              text-align: center;
              font-size: 28px;
            }
          }

          p {
            color: #727272;
            /* copy h1 */
            font-family: "DM Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 166.667% */
            @media (max-width: 1200px) {
              text-align: center;
              margin-bottom: 20px;
            }
          }
        }
      }

      .bottom-part {
        display: flex;
        align-items: center;
        gap: 48px;
        margin-bottom: 20px;
        @media (max-width: 1200px) {
          gap: 24px;
          flex-direction: column;
        }

        .navigation-part {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          @media (max-width: 1200px) {
            display: none;
          }

          .logos {
            display: flex;
            align-items: flex-start;
            gap: 24px;
          }
        }
      }
    }

    .right-side {
      display: flex;
      flex: 1 0 0;
      align-items: flex-start;
      justify-content: flex-end;
      background-position: center;
      background-size: contain;
      height: 100%;
      max-width: 60%;
      background-image: url("../../assets/Rectangle 2.png");
      background-repeat: no-repeat;
      @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
        background-size: cover;
        max-width: unset;
        height: unset;
        position: relative;
        width: 100%;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        clip-path: polygon(31% 0, 100% 0, 100% 95%, 7% 95%);
        filter: grayscale(0%);
        @media (max-width: 660px) {
          height: 500px;
        }
      }

      .dots-wrapper {
        display: none;
        align-items: center;
        gap: 10px;
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        @media (max-width: 1200px) {
          display: flex;
        }

        .dot {
          width: 10px;
          height: 10px;
          background-color: #ec1c23;
          border-radius: 100%;

          &.not-selected {
            opacity: 0.2;
          }
        }
      }
    }
  }
}
