.swiper {
  width: 100%;
  height: 100%;
  min-height: 400px;
  @media (max-width: 1200px) {
    min-height: unset;
  }
}
// .mySwiper > div {
//   margin-left: -7%;
//   @media (max-width: 1000px) {
//     margin-left: auto;
//   }
// }

.nav-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: 1200px) {
    justify-content: center;
  }

  .white-border-button {
    background: #ffffff;
  }

  .navigation-buttons {
    justify-content: flex-start;
    @media (max-width: 1200px) {
      display: none;
    }

    button {
      background: var(--Black-50, #f6f6f6);
    }
  }
}

.swiper-wrapper {
  height: 95%;
  min-height: 360px;
  @media (max-width: 1200px) {
    min-height: unset;
  }
}

.swiper-slide {
  text-align: center;
  background: #fff;
  height: 100%; /* Set height to 100% */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 360px;
  object-fit: cover;
  filter: grayscale(100%);
  // transition: 200ms;

  &:hover {
    filter: grayscale(0%);
  }
  @media (max-width: 1200px) {
    filter: unset;
    height: 212px;
  }
}
