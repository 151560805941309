.extra-container {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: 1800px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .elevating-wrapper {
    display: flex;
    padding: 74px 0;
    align-items: flex-start;
    gap: 80px;
    position: relative;
    width: 100%;
    max-width: 1512px;
    @media (max-width: 1320px) {
      flex-direction: column-reverse;
      gap: 40px;
      padding: 24px 0;
    }

    .image {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 173px;
      width: 50%;
      @media (max-width: 1320px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }

    .absolute-box {
      display: flex;
      padding: 16px;
      align-items: center;
      align-content: center;
      gap: 19px;
      background: var(--Black-50, #f6f6f6);
      box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: 0%;
      width: 90%;
      @media (max-width: 1450px) {
        bottom: -4%;
      }
      @media (max-width: 1380px) {
        bottom: -8%;
      }
      @media (max-width: 1320px) {
        flex-direction: column;
        transform: unset;
        left: 0%;
        align-items: flex-start;
        width: unset;
        bottom: 12%;
        gap: 5px;
      }

      @media (max-width: 650px) {
        width: 80%;
      }

      .inside-box {
        display: flex;
        padding: 8px 16px 8px 8px;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid var(--Black-100, #e7e7e7);
        background: var(--Black-0, #fff);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        @media (max-width: 1320px) {
          gap: 8px;
          padding: 12px;
          align-self: stretch;
        }

        p {
          color: #727272;
          font-family: "DM Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          @media (max-width: 1320px) {
            font-size: 14px;
          }
        }

        svg {
          background: var(--Primary-50, #fff1f1);
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      @media (max-width: 1320px) {
        align-items: center;
        justify-content: center;
      }

      p {
        color: #727272;
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        @media (max-width: 1320px) {
          text-align: center;
        }
      }

      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        @media (max-width: 1320px) {
          align-items: center;
        }

        p {
          color: #727272;
          font-family: "DM Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 162.5% */
          text-transform: uppercase;
          @media (max-width: 1320px) {
            line-height: unset;
            font-size: 14px;
            text-align: center !important;
          }
        }

        h2 {
          color: #16171a;
          /* h2 */
          font-family: "Open Sans";
          font-size: 42px;
          font-style: normal;
          font-weight: 700;
          line-height: 50.4px; /* 120% */
          @media (max-width: 1200px) {
            font-size: 28px;
            line-height: unset;
            text-align: center;
          }
        }
      }
    }
  }
}
