.layout-wrapper {
  .MuiRating-icon {
    color: var(--Primary-600, #ec1c23);
  }

  .MuiContainer-root {
    max-width: 100% !important;
    margin: 0 !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    display: flex;
    justify-content: center;
    @media (min-width: 1800px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  p {
    text-align: start;
    margin: 0;
  }

  h1 {
    text-align: start;
    margin: 0;
  }

  h2 {
    text-align: start;
    margin: 0;
  }

  h3 {
    text-align: start;
    margin: 0;
  }

  h4 {
    text-align: start;
    margin: 0;
  }

  button {
    cursor: pointer;
    border: none;
    transition: all 0.2s;
  }

  button:hover {
    opacity: 0.7;
  }

  .red-button {
    display: flex;
    padding: 24px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: var(--Primary-600, #ec1c23);
    color: var(--Black-0, #fff);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 200ms;
    &:hover {
      opacity: 0.7;
    }
    @media (max-width: 1200px) {
      display: flex;
      height: 44px;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      background: var(--Primary-600, #ec1c23);
      color: var(--Black-0, #fff);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .light-red-button {
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--Primary-600, #ec1c23);
    background: var(--Primary-100, #ffe0e1);
    color: var(--Primary-600, #ec1c23);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 200ms;
    max-height: 20px;
    @media (max-width: 1200px) {
      display: flex;
      height: 44px;
      padding: 9px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border: 1px solid var(--Primary-600, #ec1c23);
      background: var(--Primary-100, #ffe0e1);
      color: var(--Primary-600, #ec1c23);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .white-border-button {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--Black-100, #e7e7e7);
    color: var(--Black-950, #050505);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: transparent;
    transition: 200ms;
    &:hover {
      border: 1px solid #050505;
    }
  }

  .navigation-buttons {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    button {
      display: flex;
      width: 48px;
      height: 48px;
      padding: 13.6px 14.4px 15.2px 14.4px;
      justify-content: center;
      align-items: center;

      svg {
        fill: black;
      }
    }

    button:hover {
      background: #16171a;
      opacity: unset;

      &.red {
        background: red;
      }

      svg {
        fill: #ffffff;
      }
    }
  }
}
