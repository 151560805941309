.container {
  height: 144px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  @media (max-width: 1200px) {
    display: flex;
    padding: 10px;
    padding-right: 30px;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-wrap: wrap;
    height: unset;
  }
  @media (max-width: 1200px) {
    padding-right: 10px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  @media (max-width: 1200px) {
    margin-left: 0;
    margin-right: 0;
  }
}

li::marker {
  font-size: 20px;
  color: #EC1C23;
  text-align: right;
}