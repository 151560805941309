.client-say-wrapper {
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  background: #000;
  width: 100%;
  @media (min-width: 1800px) {
    padding: 80px 0;
  }

  .top-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    // padding-right: 5%;

    p {
      color: var(--Primary-600, #ec1c23);
      text-align: center;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 162.5% */
      text-transform: uppercase;
      @media (max-width: 1200px) {
        font-size: 14px;
      }
    }

    h2 {
      color: var(--Black-0, #fff);
      text-align: center;
      font-family: "Open Sans";
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 50.4px; /* 120% */
      @media (max-width: 1200px) {
        font-size: 28px;
      }
    }
  }

  .swiper {
    max-width: calc(1512px);
  }
  .swiper-slide {
    min-height: 360px;

    .inside-swiper {
      display: flex;
      padding: 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      flex-shrink: 0;
      align-self: stretch;
      @media (max-width: 1200px) {
        display: inline-flex;
        padding: 37px 21px 23px 21px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }

      .top-part {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        flex: 1 0 0;
        align-self: stretch;
        justify-content: space-between;

        h2 {
          color: #16171a;
          font-family: "DM Sans";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 28.8px */
          @media (max-width: 1200px) {
            font-size: 20px;
            line-height: unset;
          }
        }
      }

      h3 {
        color: var(--Primary-600, #ec1c23);
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 162.5% */
        text-transform: uppercase;
        @media (max-width: 1200px) {
          line-height: unset;
          font-size: 14px;
        }
      }

      .positive-part {
        display: flex;
        align-items: center;
        gap: 5px;
        @media (max-width: 1200px) {
          flex-wrap: wrap;
        }

        p {
          color: var(--Black-950, #050505);
          font-family: "DM Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          @media (max-width: 1200px) {
            font-size: 14px;
            line-height: unset;
          }

          &.bold {
            font-weight: 700;
          }
        }
      }

      .text {
        color: var(--Black-500, #6d6d6d);
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        @media (max-width: 1200px) {
          font-size: 14px;
          line-height: unset;
        }
      }
    }
  }
}
