.extra-container-choose-us {
  display: flex;
  align-items: center;
  width: 100%;
  background: #f2e9e9;
  justify-content: center;

  .MuiContainer-root {
    background-image: url("../../assets/Ellipse 2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    @media (max-width: 1200px) {
      background-position: top;
    }
  }

  .why-choose-us-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
    padding: 80px 0;
    max-width: calc(1512px);

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      p {
        color: var(--Primary-600, #ec1c23);
        text-align: center;
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 162.5% */
        text-transform: uppercase;
      }

      h2 {
        color: #16171a;
        text-align: center;
        font-family: "Open Sans";
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 50.4px; /* 120% */
      }
    }

    .carts-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      @media (max-width: 1200px) {
        flex-direction: column;
      }

      .cart {
        display: flex;
        padding: 40px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        background: var(--Black-0, #fff);

        .top-part-circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          flex-shrink: 0;
          border-radius: 100%;
          border: 8px solid #f3f3f3;
        }

        .bottom-part {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          padding: unset;

          h3 {
            color: #16171a;
            text-align: center;
            font-family: "DM Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 28.8px */
          }

          p {
            color: #727272;
            text-align: center;

            /* copy h2 */
            font-family: "DM Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
          }
        }
      }
    }
  }
}
